@media (max-width: 984px) {
  .about-meher-baba-container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .about-meher-baba {
    flex-direction: column;
    align-items: center; /* Center horizontally */
    text-align: center; /* Center all text */
  }

  .meher-baba-image {
    order: -1;
    width: 80%; /* Occupy full width */
    max-width: 100%; /* Limit to container width */
    height: auto; /* Maintain aspect ratio */
    margin: 20px;
    object-fit: contain; /* Maintain aspect ratio without distortion */
  }
}
